.navbar {
  position: relative;
}

.page-header {
  margin-top: 1em;
  margin-bottom: 1em;
}

.hide {
  display: none;
}

table {
  margin-top: 1em;
}

.ui-icon {
  width: 14px;
  height: 14px;
}
.offline {
  border-top: 2px solid #ddd;
  background-color: #f9f9f9;
  background: linear-gradient(180deg, rgba(249,249,249,1) 0%, rgba(255,255,255,1) 100%);
}
.online {
  border-top: 2px solid #66cc99;
  background-color: #f6fff9;
  background: linear-gradient(180deg, rgba(238,255,246,1) 0%, rgba(249,255,252,1) 100%);
}
.lds-dual-ring {
  position: absolute;
  display: inline-block;
  width: 20px;
  height: 20px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 18px;
  height: 18px;
  margin: 0.25em 0.75em;
  border-radius: 50%;
  border: 2px solid #999;
  border-color: #999 transparent #999 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}